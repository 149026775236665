// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import { ResultCard } from "@appbaseio/reactivesearch";
// import { MdArrowForwardIos } from "react-icons/md";
// import { Link } from "react-router-dom";
// import { isTablet, isDesktop } from "react-device-detect";
// import {
//   MdOutlineArrowBackIos,
//   MdOutlineArrowForwardIos,
// } from "react-icons/md";

// export const rating = (value) =>
//   new Intl.NumberFormat("en-IN", {}).format(value);

// export const numberFormat = (value) =>
//   new Intl.NumberFormat("en-IN", {
//     style: "currency",
//     currency: "INR",
//   }).format(value);

// const BestProduct = ({ data }) => {
//   console.log("DATA",data)
//   const [page, setPage] = useState(1);
//   const total = data.length;
//   const pageSize = isDesktop ? 5 : isTablet ? 3 : 1;

//   const history = useHistory();
//   const redirectUrl = (value) => {
//     //return window.open(`/url?${value}`, "_blank");
//   };

//   const openUrl = (value) => {
//     ``; //return window.open(`/search?q=%22${value}%22`, "_self");
//   };

//   const handleViewAllClick = () => {
//     history.push("/best-products");
//   };

//   // Calculate total pages
//   const totalPages = Math.ceil(total / pageSize);

//   // Calculate start and end indexes
//   const startIndex = (page - 1) * pageSize;
//   const endIndex = Math.min(startIndex + pageSize - 1, total - 1);

//   // Handle page change
//   const handlePageChange = (newPage) => {
//     // Ensure newPage is within valid range
//     if (newPage >= 1 && newPage <= totalPages) {
//       setPage(newPage);
//     }
//   };

//   const newList = data;

//   return (
//     <div className="container-fluid">
//       <div className=" d-flex justify-content-between">
//         <div className="UINames">
//           <h5 className="my-3">BEST PRODUCTS</h5>
//         </div>
//         <div>
//           <h5 className="my-3" onClick={handleViewAllClick}>
//             <Link to="/best-products" className="text-dark">
//               VIEW ALL <MdArrowForwardIos />
//             </Link>
//           </h5>
//         </div>
//       </div>
//       <div className="carousel slide">
//         <div className="HomePageView container-fluid row">
//           {newList.length > 0 &&
//             newList.slice(startIndex, endIndex + 1).map((item,index) => (
//               <div
//                 className="col-xs-12  col-sm-12 col-md-4 col-lg-3 mt-md-0"
//                 key={index}
//               >
//                 <ResultCard
//                   key={item.id}
//                   href={
//                     item._doc_count > 1
//                       ? "/details?q=%22" + item.TITLE + "%22"
//                       : "/url?id=" + item.ID
//                   }
//                   target={item._doc_count > 1 ? "_self" : "_blank"}
//                   >
//                   <ResultCard.Description>
//                     <span>
//                       <div className="ps-capitalize">
//                         { item.COUPON }
//                       </div>
//                     </span>
//                     <span>
//                       <div
//                         className="ps-compare-price"
//                         onClick={(e) => {
//                           if (item._doc_count > 1) {
//                               window.location.href = "/details?q=%22" + item.TITLE + "%22" + `&title=${item.MODEL}`;
//                           } else {
//                               window.open( "/url?id=" + item.ID, '_blank');
//                           }
//                       }}
//                       >
//                         {item._doc_count > 1 ? "Compare Price (" : null}{" "}
//                         <span className="item-count">
//                           {item._doc_count > 1 ? item._doc_count : null}
//                         </span>{" "}
//                         <span className="for-store">
//                           {item._doc_count > 1 ? "Stores)" : null}
//                         </span>
//                         <div> </div>......
//                       </div>
//                     </span>
//                   </ResultCard.Description>

//                   <ResultCard.Image src={item.IMAGE_URL} />
//                   <ResultCard.Description>
//                     {item.BRAND ? (
//                       <div className="text-center">
//                         Brand: <b>{item.BRAND}</b>
//                       </div>
//                     ) : (
//                       <div>
//                         <span className="align-left">
//                           <span className="badge  badge-success">
//                             {item.RATING_STAR
//                               ? rating(item.RATING_STAR) + "*"
//                               : null}{" "}
//                           </span>
//                           <span className="small card-subtitle mb-2 text-muted">
//                             {" "}
//                             {item.RATING_COUNT
//                               ? "(" + item.RATING_COUNT + ")"
//                               : null}
//                           </span>
//                         </span>

//                         <span className="float-right">
//                           @{" "}
//                           <span className="authors-list mb-2 text-muted">
//                             {item.SELLER_NAME}
//                           </span>
//                         </span>
//                       </div>
//                     )}
//                   </ResultCard.Description>

//                   <ResultCard.Title>
//                     <div
//                       className="text-wrap"
//                       dangerouslySetInnerHTML={{
//                         __html: item.TITLE,
//                       }}
//                     />
//                     <span>
//                       <br />
//                     </span>
//                   </ResultCard.Title>

//                   <ResultCard.Description>
//                     <div>
//                       <span>
//                         <small>
//                           <strike>
//                             {item.ORIGINAL_PRICE
//                               ? numberFormat(item.ORIGINAL_PRICE)
//                               : null}
//                           </strike>
//                         </small>
//                         <b className="ml-2">
//                           {item.OFFER_PRICE
//                             ? numberFormat(item.OFFER_PRICE)
//                             : null}{" "}
//                         </b>
//                       </span>
//                       <span className="float-right">
//                         <div className="badge  badge-warning">
//                           <span>(</span>
//                           <span>
//                             {item.DISCOUNT_PERCENTAGE
//                               ? item.DISCOUNT_PERCENTAGE + "% OFF"
//                               : "Get-Price"}
//                           </span>
//                           <span>)</span>
//                         </div>
//                       </span>
//                       {item.CASHBACK_AMOUNT ? (
//                         <div className="text-center border my-3 bg-light">
//                           <b>
//                             {" "}
//                             + {numberFormat(item.CASHBACK_AMOUNT)} Cashback
//                           </b>
//                         </div>
//                       ) : null}
//                       {item.FINAL_PRICE ? (
//                         <div className="text-center text-info">
//                           Final Price <b> {numberFormat(item.FINAL_PRICE)} </b>
//                         </div>
//                       ) : null}
//                     </div>
//                   </ResultCard.Description>
//                 </ResultCard>
//               </div>
//             ))}
//         </div>
//         <div>
//           {/* Display pagination buttons */}
//           <button
//             type="button"
//             className="btn btn-warning  prev1"
//             onClick={() => handlePageChange(page - 1)}
//             disabled={page === 1}
//           >
//             <MdOutlineArrowBackIos />
//           </button>

//           <button
//             type="button"
//             className="mb-2 btn btn-warning next1"
//             onClick={() => handlePageChange(page + 1)}
//             disabled={page === totalPages}
//           >
//             <MdOutlineArrowForwardIos />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BestProduct;


import React from "react";
import { useHistory } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { ResultCard } from "@appbaseio/reactivesearch";
import "../App.css";
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

  

const BestProduct = ({ data }) => {
  const history = useHistory();
  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };

  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  const handleViewAllClick = () => {
    history.push("/best-products");
  };

  return (
    <div className="container-fluid">
      <div className=" d-flex justify-content-between">
      <div className="UINames">
           <h5 className="my-3">BEST PRODUCTS</h5>
         </div>
        <div>
          <h5 className="my-3" onClick={handleViewAllClick}>
            <Link to="/best-products" className="Link text-dark">
              VIEW ALL <MdArrowForwardIos />
            </Link>
          </h5>
        </div>
      </div>

      <div className="HomePageView container-fluid row ">
        {data.map((item, index) => (
          <div className="col-xs-12  col-sm-12 col-md-4 col-lg-3" key={index}>
            <ResultCard
                  key={item.id}
                  href={
                    item._doc_count > 1
                      ? "/details?q=%22" + item.TITLE + "%22"
                      : "/url?id=" + item.ID
                  }
                  target={item._doc_count > 1 ? "_self" : "_blank"}
                  >
                  <ResultCard.Description>
                    <span>
                      <div className="ps-capitalize">
                        { item.COUPON }
                      </div>
                    </span>
                    <span>
                      <div
                        className="ps-compare-price"
                        onClick={(e) => {
                          if (item._doc_count > 1) {
                              window.location.href = "/details?q=%22" + item.TITLE + "%22" + `&title=${item.MODEL}`;
                          } else {
                              window.open( "/url?id=" + item.ID, '_blank');
                          }
                      }}
                      >
                        {item._doc_count > 1 ? "Compare Price (" : null}{" "}
                        <span className="item-count">
                          {item._doc_count > 1 ? item._doc_count : null}
                        </span>{" "}
                        <span className="for-store">
                          {item._doc_count > 1 ? "Stores)" : null}
                        </span>
                        <div> </div>......
                      </div>
                    </span>
                  </ResultCard.Description>

                  <ResultCard.Image src={item.IMAGE_URL} />
                  <ResultCard.Description>
                    {item.BRAND ? (
                      <div className="text-center">
                        Brand: <b>{item.BRAND}</b>
                      </div>
                    ) : (
                      <div>
                        <span className="align-left">
                          <span className="badge  badge-success">
                            {item.RATING_STAR
                              ? rating(item.RATING_STAR) + "*"
                              : null}{" "}
                          </span>
                          <span className="small card-subtitle mb-2 text-muted">
                            {" "}
                            {item.RATING_COUNT
                              ? "(" + item.RATING_COUNT + ")"
                              : null}
                          </span>
                        </span>

                        <span className="float-right">
                          @{" "}
                          <span className="authors-list mb-2 text-muted">
                            {item.SELLER_NAME}
                          </span>
                        </span>
                      </div>
                    )}
                  </ResultCard.Description>

                  <ResultCard.Title>
                    <div
                      className="text-wrap"
                      dangerouslySetInnerHTML={{
                        __html: item.TITLE,
                      }}
                    />
                    <span>
                      <br />
                    </span>
                  </ResultCard.Title>

                  <ResultCard.Description>
                    <div>
                      <span>
                        <small>
                          <strike>
                            {item.ORIGINAL_PRICE
                              ? numberFormat(item.ORIGINAL_PRICE)
                              : null}
                          </strike>
                        </small>
                        <b className="ml-2">
                          {item.OFFER_PRICE
                            ? numberFormat(item.OFFER_PRICE)
                            : null}{" "}
                        </b>
                      </span>
                      <span className="float-right">
                        <div className="badge  badge-warning">
                          <span>(</span>
                          <span>
                            {item.DISCOUNT_PERCENTAGE
                              ? item.DISCOUNT_PERCENTAGE + "% OFF"
                              : "Get-Price"}
                          </span>
                          <span>)</span>
                        </div>
                      </span>
                      {item.CASHBACK_AMOUNT ? (
                        <div className="text-center border my-3 bg-light">
                          <b>
                            {" "}
                            + {numberFormat(item.CASHBACK_AMOUNT)} Cashback
                          </b>
                        </div>
                      ) : null}
                      {item.FINAL_PRICE ? (
                        <div className="text-center text-info">
                          Final Price <b> {numberFormat(item.FINAL_PRICE)} </b>
                        </div>
                      ) : null}
                    </div>
                  </ResultCard.Description>
                </ResultCard>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestProduct;
