// Breadcrumb.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");
  const queryParams = new URLSearchParams(location.search);
  const q = queryParams.get('q');
  return (
    <Breadcrumb>
      <Breadcrumb.Item as={Link} to="/">
        <Link to="/">
          <small className="text-dark"> Home</small>
        </Link>
      </Breadcrumb.Item>
      {paths.map((path, index) => (
  <Breadcrumb.Item
    key={index}
    as={Link}
    to={`/${paths.slice(0, index + 1).join("/")}`}
  >
    <Link to={path === 'category' ? '/deals-by-category' : (path === 'details' ? '/best-products' : `/${path}`)}>
      <small className="text-capitalize text-dark">
        {path === 'category' ? 'deals-by-category' : (path === 'details' ? 'best-products' : path)}
      </small>
    </Link>
  </Breadcrumb.Item>
))}
      {q && (
        <Breadcrumb.Item active>
          <small className="text-capitalize text-dark">{q}</small>
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
