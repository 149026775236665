import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

export const ContactUs = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Header />
      <div class="about-section paddingTB60 gray-bg">
        <div
          class="row"
          style={{
            paddingTop: "6%",
            paddingBottom: "6%",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
        >
          <div class="col-md-9 col-md-offset-3">
            <div class="well well-sm">
              <main ng-controller="BaseFormCtrl" class="ng-scope">
                <form
                  class="form-horizontal ng-pristine ng-valid ng-scope"
                  ng-submit="onContactUs()"
                  ng-controller="ContactusCtrl"
                >
                  <fieldset>
                    <legend class="text-center">Contact us</legend>

                    <div class="form-group">
                      <label class="col-md-4 control-label" for="name">
                        Name
                      </label>
                      <div class="col-md-9">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          ng-model="vm.contactusRequestorName"
                          placeholder="Your name"
                          class="form-control ng-pristine ng-untouched ng-valid"
                        />
                      </div>

                      <div class="form-group">
                        <label class="col-md-4 control-label" for="email">
                          Your E-mail *
                        </label>
                        <div class="col-md-9">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            ng-model="vm.contactusRequestorEmail"
                            placeholder="Your email"
                            class="form-control ng-pristine ng-untouched ng-valid"
                          />
                        </div>

                        <div class="form-group">
                          <label class="col-md-4 control-label" for="message">
                            Your message
                          </label>
                          <div class="col-md-9">
                            <textarea
                              class="form-control ng-pristine ng-untouched ng-valid"
                              id="message"
                              name="message"
                              ng-model="vm.contactusRequestorMsg"
                              placeholder="Please enter your message here..."
                              rows="5"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="col-md-12 text-right">
                            <button
                              type="submit"
                              class="btn btn-primary btn-lg"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
