import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
export const PrivacyAndPolicy = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Header />
      <div className="container">
        <div
          className="row"
          style={{
            paddingTop: "6%",
            paddingBottom: "6%",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
        >
          <h3>Our Commitment to Privacy</h3>
          <p>
            Our Privacy Policy was developed as an extension of our commitment
            to combine the highest-quality products and services with the
            highest level of integrity in dealing with our clients and partners.
            The Policy is designed to assist you in understanding how we
            collect, use and safeguard the personal information you provide to
            us and to assist you in making informed decisions when using our
            site and our products and services. This statement will be
            continuously assessed against new technologies, business practices
            and our customers' needs.
          </p>
          <h6>What Information Do We Collect?</h6>
          <p>
            When you visit our Web site you may provide us with two types of
            information: personal information you knowingly choose to disclose
            that is collected on an individual basis and Web site use
            information collected on an aggregate basis as you and others browse
            our Web site.
          </p>
          <ol>
            <li>
              <h6>Personal Information You Choose to Provide</h6>
            </li>
            <div>-Name</div>
            <div>-Email</div>
            <li>
              <h6>Web Site Use Information</h6>
              <p>
                Similar to other commercial Web sites, our Web site utilizes a
                standard technology called "cookies" and Web server logs to
                collect information about how our Web site is used. Information
                gathered through cookies and Web server logs may include the
                date and time of visits, the pages viewed, time spent at our Web
                site, and the Web sites visited just before and just after our
                Web site. This information is collected on an aggregate basis.
                None of this information is associated with you as an
                individual.
              </p>
            </li>
          </ol>
          <h6>How Do We Use the Information That You Provide to Us?</h6>
          <p>
            The users who subscribe to newsletters or email notifications of
            PlanetOnSale will often receive mails possessing the updates about
            improvements and changes as well as offers of PlanetOnSale website.
            This may also include exclusive promotions, products and service
            information or special deals (third party’s as well as
            PlanetOnSale’s).
          </p>

          <h6>How Do We Use Information We Collect from Cookies?</h6>
          <p>
            We use Web site browser software tools such as cookies and Web
            server logs to gather information about our Web site users' browsing
            activities, in order to constantly improve our Web site and better
            serve our customers. This information assists us to design and
            arrange our Web pages in the most user-friendly manner and to
            continually improve our Web site to better meet the needs of our
            customers and prospective customers. Cookies help us collect
            important business and technical statistics. The information in the
            cookies lets us trace the paths followed by users to our Web site as
            they move from one page to another. Web server logs allow us to
            count how many people visit our Web site and evaluate our Web site's
            visitor capacity. We do not use these technologies to capture your
            individual email address or any personally identifying information
            about you although they do permit us to send focused online banner
            advertisements or other such responses to you.
          </p>

          <h6>How Do We Protect Your Information?</h6>
          <p>
            Information Security. We utilize encryption/security software to
            safeguard the confidentiality of personal information we collect
            from unauthorized access or disclosure and accidental loss,
            alteration or destruction.
          </p>
          <h6>Communication Opt Out</h6>
          <p>
            If you wish to opt out of receiving offers directly from
            PlanetOnSale, you can unsubscribe or follow the opt-out instructions
            in the emails that they send you located at the footer of the
            communication.
          </p>
          <h6>What About Other Web Sites Linked to Our Web Site?</h6>
          <p>
            When you visit and navigate on our website, some of our services may
            direct you to third party web domains. Personal information shared
            by you with these third parties web domains will be dealt according
            to their privacy policy. PlanetOnSale not be responsible for the
            information you share with these third party web domains.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
