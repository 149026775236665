import React, { useState, useEffect } from "react";
import Menu from "../Mobile/Menu";
import { Link } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import Footer from "../layout/Footer";
import Breadcrumbs from "../layout/Breadcrumb";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Header from "../layout/Header";
import "../App.css";

import {
  ReactiveBase,
  ReactiveList,
  MultiList,
  MultiRange,
  SelectedFilters,
  ResultCard,
} from "@appbaseio/reactivesearch";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { elasticSearchUrl } from "../common/util";
import ScrollButton from "../ScrollButton";
export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

const ViewAllTrending = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const [sidePriceMenu, setSidePriceMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [checkIsMobile, setCheckIsMobile] = useState(false);
  const [data, setData] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedModal, setSelectedModal] = useState([]);
  const [sideMenuClicked, setSideMenuClicked] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [filter, setFilter] = useState(true);
  const [searchProduct, setSearchProduct] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobile(false);
        setCheckIsMobile(true);
      } else {
        setIsMobile(true);
        setCheckIsMobile(true);
      }
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDiscount = (e) => {
    setSelectedDiscount(e);
  };

  const handlePriceRange = (e) => {
    setSelectedPrice(e);
  };

  const filterData = (visible) => {
    setSideMenu(visible);
    setFilter(false);
  };
  const filterPriceData = (visible) => {
    setSidePriceMenu(visible);
  };

  const applyData = () => {
    setSideMenu(false);
    setFilter(true);
  };

  const checkboxScrollForSingleArray = (value) => {
    const length = value.length;

    if (length > 0) {
      const arr = [length];
      const mainArray = [...sideMenuClicked, arr];
      setSideMenuClicked(mainArray);
    }

    if (length === 0) {
      const updatedSideMenuClicked = [...sideMenuClicked];
      updatedSideMenuClicked.pop();
      setSideMenuClicked(updatedSideMenuClicked);
    }
  };

  const redirectUrl = (value) => {
    //return window.open(`/url?${value}`, "_blank");
  };
  const openUrl = (value) => {
    ``; //return window.open(`/search?q=%22${value}%22`, "_self");
  };

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <Header  setSearchProduct={setSearchProduct}/>
        <div>
          <Breadcrumbs className="bg-white" />
        </div>
        <div className="row pop-box mt-4 justify-content-center">
          <div
            className="col-12 col-lg-3 col-md-3 col-sm-4 pad-rm filter"
            style={{
              display: sideMenu ? "" : "none",
              position: "fixed",
              zIndex: "99999",
              bottom: "40px",
              height: "95%",
            }}
          >
            <div className="col-12 col-lg-3 col-md-3 col-sm-8 tab-setup-left">
              <div className="tab-wrapper">
                <h1>Best Products OnSale & Live Deals in India.</h1>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <Tab.Container defaultActiveKey="best">
                        <Row>
                          <Col xs={4} sm={4} className="de-nn">
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="best">Discount %</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="category">
                                  Category
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="store">Store</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col xs={8} sm={8} className="right-box">
                            <Tab.Content>
                              <Tab.Pane eventKey="best">
                                <div className="box">
                                  <MultiRange
                                    componentId="discountFilter"
                                    dataField="DISCOUNT_PERCENTAGE"
                                    title="Discount % "
                                    data={[
                                      {
                                        start: 80,
                                        end: 100,
                                        label: "80% to 100% Off",
                                      },
                                      {
                                        start: 60,
                                        end: 80,
                                        label: "60% to 80% Off",
                                      },
                                      {
                                        start: 40,
                                        end: 60,
                                        label: "40% to 60% Off",
                                      },
                                      {
                                        start: 0,
                                        end: 40,
                                        label: "Below 40% Off",
                                      },
                                    ]}
                                    defaultQuery={() => {
                                      return { 
                                        "query": {
                                          "bool": {
                                            "must": [
                                              { "match": { "IS_TRENDING": "Y" }},
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      };
                                    }}
                                    react={{
                                      and: ["q","CategorySensor"]
                                    }}
                                    filterLabel="Discount"
                                  />

                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="category">
                                <div className="box">
                                  <MultiList
                                    componentId="CategorySensor"
                                    dataField="CATEGORY.keyword"
                                    showCount={true}
                                    showSearch={false}
                                    size={20}
                                    title="Category"
                                    placeholder="Search from Category"
                                    filterData={true}
                                    defaultQuery={() => {
                                      return { 
                                        "query": {
                                          "bool": {
                                            "must": [
                                              { "match": { "IS_TRENDING": "Y" }},
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }                                            ]
                                          }
                                        }
                                      };
                                    }}
                                    react={{
                                      and: ["q","discountFilter"]
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="store">
                                <div className="box">
                                  <MultiList
                                    componentId="StoreFilter"
                                    dataField="SELLER_NAME.keyword"
                                    showSearch={true}
                                    showCount={true}
                                    title="Store"
                                    placeholder="Search from Store"
                                    sortBy="asc"
                                    defaultQuery={() => {
                                      return { 
                                        "query": {
                                          "bool": {
                                            "must": [
                                              { "match": { "IS_TRENDING": "Y" }},
                                              { 
                                                "bool": {
                                                  "should": [
                                                    { "match": { "STATUS": 1 }},
                                                    { "match": { "STATUS": 2 }}
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      };
                                    }}
                                    react={{
                                      and: ["q","discountFilter","CategorySensor"]
                                    }}
                                  />
                                  <button
                                    onClick={() => {
                                      applyData();
                                    }}
                                    className="bestaply"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-md-block  col-md-3 col-12 col-lg-6  col-sm-12">
            <div className="p-2">
              <p className="border-bottom pb-1">FILTER BY</p>
              <div className="border-bottom pb-3">
                <div className="box">
                  <MultiRange
                    componentId="discountFilter"
                    dataField="DISCOUNT_PERCENTAGE"
                    title="Discount % "
                    data={[
                      {
                        start: 80,
                        end: 100,
                        label: "80% to 100% Off",
                      },
                      {
                        start: 60,
                        end: 80,
                        label: "60% to 80% Off",
                      },
                      {
                        start: 40,
                        end: 60,
                        label: "40% to 60% Off",
                      },
                      {
                        start: 0,
                        end: 40,
                        label: "Below 40% Off",
                      },
                    ]}
                    defaultQuery={() => {
                      return { 
                        "query": {
                          "bool": {
                            "must": [
                              { "match": { "IS_TRENDING": "Y" }},
                              { 
                                "bool": {
                                  "should": [
                                    { "match": { "STATUS": 1 }},
                                    { "match": { "STATUS": 2 }}
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      };
                    }}
                    react={{
                      and: ["q","StoreFilter","CategoryFilter"]
                    }}
                    filterLabel="Discount"
                  />
                </div>
                <div className="box">
                <MultiList
                  componentId="CategoryFilter"
                  dataField="CATEGORY.keyword"
                  showCount={true}
                  showSearch={false}
                  size={20}
                  title="Category"
                  placeholder="Search from Category"
                  filterData={true}
                  defaultQuery={() => {
                    return { 
                      "query": {
                        "bool": {
                          "must": [
                            { "match": { "IS_TRENDING": "Y" }},
                            { 
                              "bool": {
                                "should": [
                                  { "match": { "STATUS": 1 }},
                                  { "match": { "STATUS": 2 }}
                                ]
                              }
                            }
                          ]
                        }
                      }
                    };
                  }}
                  react={{
                    and: ["q","discountFilter","StoreFilter"]
                  }}
                />
                </div>
                <div className="box">
                <MultiList
                  componentId="StoreFilter"
                  dataField="SELLER_NAME.keyword"
                  showSearch={true}
                  showCount={true}
                  title="Store"
                  placeholder="Search from Store"
                  sortBy="asc"
                  defaultQuery={() => {
                    return { 
                      "query": {
                        "bool": {
                          "must": [
                            { "match": { "IS_TRENDING": "Y" }},
                            { 
                              "bool": {
                                "should": [
                                  { "match": { "STATUS": 1 }},
                                  { "match": { "STATUS": 2 }}
                                ]
                              }
                            }
                          ]
                        }
                      }
                    };
                  }}
                  react={{
                    and: ["q","discountFilter","CategoryFilter"]
                  }}
                />
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => filterData(!sideMenu)} className="bestbtnss">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="result-icon"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" opacity="0.05"></path>
                <path
                  fill="#282C3F"
                  d="M3.749 7.508a.75.75 0 010-1.5h3.138a2.247 2.247 0 014.243 0h9.121a.75.75 0 010 1.5h-9.126a2.248 2.248 0 01-4.232 0H3.749zm13.373 9h3.129a.75.75 0 010 1.5h-3.135a2.247 2.247 0 01-4.231 0H3.749a.75.75 0 010-1.5h9.13a2.248 2.248 0 014.243 0z"
                ></path>
              </g>
            </svg>
            Filter
          </button>
        
          <div className="col-sm-12 col-md-9 col-lg-9 justify-content-center p-0">
            <h4 className="text-center">TRENDING DEALS</h4>
            <SelectedFilters />
            <ScrollButton/>
           <ReactiveList
              componentId="TrendingDeals"
              dataField="IS_TRENDING"
              distinctField="COUPON.keyword"
              aggregationField="COUPON.keyword"
              defaultQuery={() => {
                return { 
                  "query": {
                    "bool": {
                      "must": [
                        { "match": { "IS_TRENDING": "Y" }},
                        { 
                          "bool": {
                            "should": [
                              { "match": { "STATUS": 1 }},
                              { "match": { "STATUS": 2 }}
                            ]
                          }
                        }
                      ]
                    }
                  }
                };
              }}
              sortOptions={[
                {
                  dataField: "DATE.keyword",
                  sortBy: "desc",
                  label: "Sort by Newest Deals \u00A0 \u00A0",
                },
                {
                  dataField: "RATING_STAR",
                  sortBy: "desc",
                  label: "Sort by Popularity  \u00A0",
                },
                {
                  dataField: "DISCOUNT_PERCENTAGE",
                  sortBy: "desc",
                  label: "Sort by Discount %(High to Low)\u00A0 \u00A0",
                },
                {
                  dataField: "SELLER_NAME",
                  sortBy: "asc",
                  label: "Sort by A-Z\u00A0",
                },
                
              ]}
              pagination={false}
              stream={true}
              size={1000}
              scrollOnChange={false}
              loader
              react={{
                and: [
                  "trendingProducts",
                  "discountFilter",
                  "CategorySensor",
                  "StoreFilter",
                  "CategoryFilter",
                  "q"
                ],
              }}
              render={({ data }) => (
                <ReactiveList.ResultCardsWrapper>
                  <div className="container row d-flex justify-content-center p-0">
                    {data.map((item,index) => (
                      <div className="d-flex mx-1 col-md-4 col-xl-3 col-sm-12 justify-content-center" key={index}>
                        <ResultCard
                          key={item.id}
                          href={
                            item._doc_count > 1
                              ? "/search?q=%22" + item.TITLE + "%22"
                              : "/url?id=" + item.ID
                          }
                          target={item.MODEL ? "_self" : "_blank"}
                          onClick={(e) => {
                            item._doc_count > 1
                              ? openUrl(item.TITLE)
                              : redirectUrl(item.OFFER_URL);
                          }}
                        >
                          <ResultCard.Description>
                            <span>
                              <div className="ps-capitalize">
                              {item._doc_count > 1 && isNaN(item.COUPON) ? item.COUPON : null}
                              </div>
                            </span>
                            <span>
                              <div
                                className="ps-compare-price"
                                onClick={(e) => {
                                  window.location.href =
                                    item._doc_count > 1
                                      ? "/search?q=%22" +
                                        item.TITLE +
                                        "%22" +
                                        `&title=${item.MODEL}`
                                        : "/url?id=" + item.ID +
                                        `&title=${item.MODEL}`;
                                }}
                              >
                                {item._doc_count > 1 ? "Compare Price (" : null}{" "}
                                <span className="item-count">
                                  {item._doc_count > 1 ? item._doc_count : null}
                                </span>{" "}
                                <span className="for-store">
                                  {item._doc_count > 1 ? "Stores)" : null}
                                </span>
                                <div> </div>......
                              </div>
                            </span>
                          </ResultCard.Description>

                          <ResultCard.Image src={item.IMAGE_URL} />
                          <ResultCard.Description>
                            {item.BRAND ? (
                              <div className="text-center">
                                Brand: <b>{item.BRAND}</b>
                              </div>
                            ) : (
                              <div>
                                <span className="align-left">
                                  <span className="badge  badge-success">
                                    {item.RATING_STAR
                                      ? rating(item.RATING_STAR) + "*"
                                      : null}{" "}
                                  </span>
                                  <span className="small card-subtitle mb-2 text-muted">
                                    {" "}
                                    {item.RATING_COUNT
                                      ? "(" + item.RATING_COUNT + ")"
                                      : null}
                                  </span>
                                </span>

                                <span className="float-right">
                                  @{" "}
                                  <span className="authors-list mb-2 text-muted">
                                    {item.SELLER_NAME}
                                  </span>
                                </span>
                              </div>
                            )}
                          </ResultCard.Description>

                          <ResultCard.Title>
                            <div
                              className="text-wrap"
                              dangerouslySetInnerHTML={{
                                __html: item.TITLE,
                              }}
                            />
                            <span>
                              <br />
                            </span>
                          </ResultCard.Title>

                          <ResultCard.Description>
                            <div>
                              <span>
                                <small>
                                  <strike>
                                    {item.ORIGINAL_PRICE
                                      ? numberFormat(item.ORIGINAL_PRICE)
                                      : null}
                                  </strike>
                                </small>
                                <b className="ml-2">
                                  {item.OFFER_PRICE
                                    ? numberFormat(item.OFFER_PRICE)
                                    : null}{" "}
                                </b>
                              </span>
                              <span className="float-right">
                                <div className="badge  badge-warning">
                                  <span>(</span>
                                  <span>
                                    {item.DISCOUNT_PERCENTAGE
                                      ? item.DISCOUNT_PERCENTAGE + "% OFF"
                                      : "Get-Price"}
                                  </span>
                                  <span>)</span>
                                </div>
                              </span>
                            </div>
                          </ResultCard.Description>
                        </ResultCard>
                      </div>
                    ))}
                  </div>
                </ReactiveList.ResultCardsWrapper>
                
              )}
            /> 

          </div>
        </div>
        <Footer />
        <br></br>
      </ReactiveBase>
    </div>
  );
};

export default ViewAllTrending;
