import { restUrl } from "../common/util";
const generateOTP = (phoneNumber) => {
  return fetch(`${restUrl}/generate-otp/${phoneNumber}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const verifyOTP = (data) => {
  return fetch(`${restUrl}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const registerOTP = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/register/otp`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const registerWithEmail = (data) => {
  return fetch(`${restUrl}/register/basic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const resetPassword = (data) => {
  const token = localStorage.getItem("token");

  return fetch(`${restUrl}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};
const getUserDetails = () => {
  const token = localStorage.getItem("token");

  return fetch(`${restUrl}/details`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const updateEmail = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/update-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const verifyUpdateEmail = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/update-email-verify`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const updateMobile = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/update-mobile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const verifyUpdateMobile = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/update-mobile-verify`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const updatePassword = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/profile/update-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const updateName = (data) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/profile/update-name`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const verifyEmail = (code) => {
  return fetch(`${restUrl}/verify-email/${code}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const checkEmailIsExist = (email) => {
  const token = localStorage.getItem("token");
  return fetch(`${restUrl}/verify-is-email-taken/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};
const checkMobileIsExist = (mobileNumber) => {
  return fetch(`${restUrl}/verify-is-mobileNumber-taken/${mobileNumber}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const getEarningsDetails = () => {
  const token = localStorage.getItem("token");

  return fetch(`${restUrl}/my-earnings`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

const getMyOrders = (page, size) => {
  const token = localStorage.getItem("token");

  return fetch(`${restUrl}/my-orders?page=${page}&size=${size}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw console.error(error);
    });
};

export default {
  generateOTP,
  verifyOTP,
  registerOTP,
  registerWithEmail,
  resetPassword,
  getUserDetails,
  updateMobile,
  verifyUpdateMobile,
  updateEmail,
  verifyUpdateEmail,
  updatePassword,
  verifyEmail,
  updateName,
  checkEmailIsExist,
  checkMobileIsExist,
  getEarningsDetails,
  getMyOrders,
};
