import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

export const TermsAndConditions = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Header />
      <div className="container">
        <div
          className="row"
          style={{
            paddingTop: "6%",
            paddingBottom: "6%",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
        >
          <h3>
            Terms & Conditions- <a href="/">PlanetOnSale.in</a>
          </h3>
          <p>
            Please Read The Following Terms And Conditions Carefully.
            PlanetOnSale ("we," or "us"), require that all visitors to this Web
            site adhere to the following terms and conditions of use. If you do
            not agree to these Terms, you are not authorized to use the Site. BY
            ACCESSING AND USING THE SITE, YOU AGREE TO BE BOUND BY THESE TERMS.
          </p>
          <h6>1. CHANGES TO THESE TERMS AND THE SITE</h6>
          <p>
            We may change these Terms at any time, and all such changes are
            effective immediately upon notice, which we may give by any means,
            including, but not limited to, by posting a revised version of these
            Terms or other notice on the Site. You should view these Terms often
            to stay informed of changes that may affect you. Your use of the
            Site constitutes your continuing agreement to be bound by these
            Terms, as they are amended from time to time.
          </p>
          <h6>2. LICENSE TO ACCESS AND USE</h6>
          <p>
            You may access and use this Site only for your personal use. Any
            other access to or use of the Site or the Content constitutes a
            violation of these Terms and may violate applicable copyright,
            trademark, or other laws.
          </p>
          <h6>3. PRIVACY POLICY</h6>
          <p>
            Please read our Privacy Policy. In order to use our service you must
            agree with all terms or information provided in Privacy Policy
          </p>
          <h6>4. TERMINATION OF ACCESS</h6>
          <p>
            4.1 PlanetOnSale reserves the right, in its sole discretion, to
            terminate your access to all or part of the Site, without notice or
            liability, for any reason, including, but not limited to: (a) the
            unauthorized use of any username or password; or (b) the breach of
            any agreement between you and PlanetOnSale, including, without
            limitation, these Terms. 4.2 Upon being notified that your access is
            terminated, you must destroy any materials you have obtained from
            the Site. You may not access the Site after your access is
            terminated without our written approval.
          </p>
          <h6>5. USER CONDUCT</h6>
          <p>
            In connection with your access and use of the Site, you are
            responsible for complying with all applicable laws, regulations, and
            policies of all relevant jurisdictions, including all applicable
            local rules regarding online conduct.
          </p>
          <h6>6. SECURITY</h6>
          <p>
            PlanetOnSale takes such commercially reasonable measures as it deems
            appropriate to secure and protect information transmitted to and
            from the Site. Nevertheless, we cannot and do not guarantee that any
            such transmissions are or will be totally secure.
          </p>

          <h6>7. HYPERLINKS</h6>
          <p>
            This Site includes hyperlinks to other websites which are not
            maintained by PlanetOnSale. We are not responsible for the content
            of such external websites.
          </p>
          <h6>8. QUESTIONS </h6>
          <p>
            Please Contact Us with any questions regarding the Site or these
            Terms.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
