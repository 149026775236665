import React from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
export const AboutUs = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Header />
      <div className="container">
        <div
          className="row"
          style={{
            paddingTop: "6%",
            paddingBottom: "6%",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
        >
          <h3>About PlanetOnSale</h3>

          <p>
            <a href="/">PlanetOnSale.in</a> is your one-stop destination for all
            your online shopping need. It is because, our dedicated and
            specialized team list only.
            <ul>
              <li>
                Best products and its Deal prices from various stores. So you
                get the best product with the cheapest price. It saves your time
                and money.
              </li>
              <li>
                Live / Ongoing discounts (OnSale) programs from various stores.
              </li>
            </ul>
          </p>
          <h6>Which product to buy ? </h6>
          <p>
            Check our <a href="/buyers-guide/index.html">Buyer's Guide</a> for
            the Best product review and recommendation from our expert product
            team.
          </p>
          <h6>Where do I buy these products?</h6>
          <p>
            Just click on the image of any product, and you will be taken to the
            online shopping website that is selling that product. You can
            directly complete your purchase there.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
