import React, { useState, useEffect } from "react";
import "../App.css";
import Menu from "../Mobile/Menu";
import { SearchBox } from "@appbaseio/reactivesearch";
import { Link, useHistory } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ApiService from "../services/Auth";
import { IoIosArrowDown } from "react-icons/io";

const Header = (props) => {
  const history = useHistory();
  const [isLogedin, setIsLogedin] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [myEarningsData, setMyEarningsData] = useState("");
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   if (token) {
  //     setIsLogedin(true);
  //     ApiService.getUserDetails()
  //       .then((data) => {
  //         console.log(data.data);
  //         setUserDetail(data.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error in component:", error);
  //       });

  //     ApiService.getEarningsDetails()
  //       .then((data) => {
  //         console.log(data.data);
  //         setMyEarningsData(data.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error in component:", error);
  //       });
  //   }
  // }, [token]);

  const handleLogin = () => {
    if (token) {
      setIsLogedin(true);
    } else {
      history.push("/login");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    history.push("/");
    setIsLogedin(false);
  };

  return (
    <React.Fragment>
      <div className="container-fluid top-header" style={{ padding: 0 }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-header">
          <div className="d-flex justify-content-between">
            <a className="navbar-brand logo-img" href="/">
              <img
                src="https://d2qjxo7zbjilfg.cloudfront.net/images/7a602a38-a76b-4864-a40d-2d7bd6428800.png"
                height="60"
                alt="Logo of PlanetOnSale"
              />
              <b> PlanetOnSale </b>
            </a>
            <div className="mt-2">
              <Menu />
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            ></div>
          </div>
          <div className="col-lg-8 dataSearch">
            <SearchBox
              className="datasearch"
              componentId="q"
              // onValueSelected={(e) => {
              //   window.location.href = `${window.location.protocol}//${window.location.host}/search?q=%22${encodeURI(e)}%22`;
              //   props.setSearchProduct(false);
              //   document.activeElement.blur();
              // }}
              onValueSelected={(e) => {  
                                  window.location.href = `${window.location.protocol}//${window.location.host}/search?q=%22${encodeURI(e)}%22`;
                 
                  document.activeElement.blur();
             
              }}
              onValueChange={(e) => {
                if (e && e.trim().length === 1) {
                  props.setSearchProduct(false);  
                } 
               
              }}

              // onValueSelected={() => {
              //   props.setSearchProduct(false);
              //   document.activeElement.blur();
              // }}
              // onValueChange={(e) => {
              //   if (e === null) {
              //     props.setSearchProduct(true);
              //   }
              // }}
              // onValueChange={(e) => {
              //   this.setState({ carasalShow: e === null || e === "" });
              // }}
              // onValueSelected={(e) => {
              //               window.location.href = `${window.location.protocol}//${window.location.host}/?q=%22${encodeURI(e)}%22`;
              //         }}

              dataField={[
                "SEARCH_STR1",
                "SEARCH_STR1.SEARCH",
                "SEARCH_STR2",
                "SEARCH_STR2.SEARCH",
                "TITLE",
                "TITLE.SEARCH",
                "CATEGORY",
                "BRAND",
                "SELLER_NAME",
              ]}
              queryFormat="and"
              placeholder="Search for a deal (product) "
              innerClass={{
                input: "searchbox",
                list: "suggestionlist",
              }}
              autosuggest={true}
              showVoiceSearch={true}
              iconPosition="left"
              filterLabel="search"
            />
          </div>
          <li className="nav-item new-navset">
              <a  href="/buyers-guide/index.html">Buyer's Guide</a>
          </li>
         
        {/* Login Icon  */}
          {/* {isLogedin ? (
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              overlay={
                <Popover className="mt-3 overLay">
                  <Popover.Title as="h3" >
                    <Link className="text-dark" to="/my-earnings">
                      {" "}
                      My Earnings{" "}
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/my-orders">
                      My Orders{" "}
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/withdraw-money">
                      {" "}
                      Withdraw Money{" "}
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/withdrawal-history">
                      {" "}
                      Withdrawl History{" "}
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark"> Support Ticket </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" to="/personaldetails">
                      Setting
                    </Link>
                  </Popover.Title>
                  <Popover.Title as="h3">
                    <Link className="text-dark" onClick={logoutHandler}>
                      Logout
                    </Link>
                  </Popover.Title>
                </Popover>
              }
            >
              <li className="d-flex justify-content-between  nav-item new-navset ">
              <span class="border-left border-dark py-2 ml-2"></span>
                <div className="earningsTitle">
                  <Link to="/my-earnings">
                    My Earnings ₹
                    {(
                      myEarningsData.totalCashbackAmount +
                      myEarningsData.totalRewards
                    ).toFixed(2)}
                  </Link>
                </div>
                <span class="border-right border-dark py-2 ml-2"></span>
                <div className="btn ml-1" onClick={handleLogin}>
                  <IoPersonCircleOutline size={30} />
                  <small className="mt-1 mx-1 text-white">
                    {userDetail.fullName
                      ? userDetail.fullName
                      : userDetail.email
                      ? userDetail.email.split("@")[0]
                      : userDetail.tempEmail
                      ? userDetail.tempEmail.split("@")[0]
                      : ""}
                  </small>
                  <IoIosArrowDown />
                </div>
              </li>
            </OverlayTrigger>
          ) : (
            <li className="loginUser nav-item new-navset">
              <div className="btn" onClick={handleLogin}>
                <IoPersonCircleOutline size={30} />
              </div>
            </li>
          )} */}
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Header;
