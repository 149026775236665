import { BrowserRouter as Switch, Route, Router } from "react-router-dom";
import React, { Component } from "react";
import Home from "./App";
import Modal from "./common/Modal";
import Search from "./common/Search";
import CategoryDetalis from "./category-deals/CategoryDetalis";
import { history } from "./history";
import { AboutUs } from "./pages/about-us";
import { TermsAndConditions } from "./pages/terms-and-conditions";
import { ContactUs } from "./pages/contact-us";
import { PrivacyAndPolicy } from "./pages/privacy-and-policy";
import ViewAllTrending from "./trending/ViewAllTrending";
import LoginSignup from "./Authentication/Login/LoginSignup";
import OTP from "./Authentication/Login/OTP";
import LoginEmail from "./Authentication/Login/LoginEmail";
import LoginMobile from "./Authentication/Login/LoginMobile";
import SignupEmail from "./Authentication/Signin/SignupEmail";
import SetPassword from "./Authentication/Signin/SetPassword";
import ProfileName from "./Authentication/Signin/ProfileName";
import ViewAllCashBack from "./cashback/ViewAllCashBack";
import ViewAllBestProduct from "./best-product/ViewAllBestProduct";
import PersonalDetails from "./profile-setting/PersonalDetails";
import ChangePasswords from "./profile-setting/ChangePassword";
import EmailVerify from "./profile-setting/EmailVerify";
import OtpPassword from "./Authentication/Signin/OtpPassword";
import VerifyAccount from "./profile-setting/VerifyAccount";
import CreatePassword from "./profile-setting/CreatePassword";
import VerifyOtp from "./profile-setting/VerifyOtp";
import VerifyEmail from "./Authentication/Signin/VerifyEmail";
import BankDetails from "./withdraw-money/BankDetails";
import ConfirmCashback from "./withdraw-money/ConfirmCashback";
import UpiTransfer from "./withdraw-money/UpiTransfer";
import WithdrawOtp from "./withdraw-money/WithdrawOtp";
import MyEarnings from "./withdraw-money/MyEarnings";
import WithdrawMoney from "./withdraw-money/WithdrawMoney";
import MyOrders from "./my-order/MyOrders";
import WithdrawalHistory from "./withdraw-money/WithdrawalHistory";
import ViewAllCategoryDeals from "./category-deals/ViewAllCategoryDeals";
import ProductDetails from "./common/ProductDetails";

export default class Routing extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route
            exact
            path="/privacy-and-policy"
            component={PrivacyAndPolicy}
          />
          <Route path="/url" component={Modal} />
         
          <Route path="/details" component={Search} />
          <Route path="/search" component={ProductDetails} />
          <Route path="/category" component ={CategoryDetalis} />
          {/* <Redirect from="*" to="/" /> */}
          <Route exact path="/trending-deals" component={ViewAllTrending} />
          {/* <Route exact path="/cashback" component={ViewAllCashBack} /> */}
          <Route exact path="/best-products" component={ViewAllBestProduct} />
          <Route path="/deals-by-category" component={ViewAllCategoryDeals}></Route>
          {/* <Route path="/login" component={LoginSignup}></Route>
          <Route path="/otp" component={OTP}></Route>
          <Route path="/email" component={LoginEmail}></Route>
          <Route path="/mobile" component={LoginMobile}></Route>
          <Route path="/signup" component={SignupEmail}></Route>
          <Route path="/setpassword" component={SetPassword}></Route>
          <Route path="/profilename" component={ProfileName}></Route>
          <Route path="/personaldetails" component={PersonalDetails}></Route>
          <Route path="/verifyemail" component={EmailVerify}></Route>
          <Route path="/otpPassword" component={OtpPassword}></Route>
          <Route path="/changepassword" component={ChangePasswords}></Route>
          <Route path="/verifyaccount" component={VerifyAccount}></Route>
          <Route path="/createpassword" component={CreatePassword}></Route>
          <Route path="/verifyotp" component={VerifyOtp}></Route>
          <Route path="/verify-email/:code" component={VerifyEmail}></Route> */}
          {/* new screens route */}
          {/* <Route path="/bank-details" component={BankDetails}></Route>
          <Route path="/confirm-cashback" component={ConfirmCashback}></Route>
          <Route path="/upi-transfer" component={UpiTransfer}></Route>
          <Route path="/withdraw-otp" component={WithdrawOtp}></Route>
          <Route path="/my-earnings" component={MyEarnings}></Route>
          <Route path="/withdraw-money" component={WithdrawMoney}></Route>
          <Route path="/my-orders" component={MyOrders}></Route>
          <Route
            path="/withdrawal-history"
            component={WithdrawalHistory}
          ></Route> */}
        </Switch>
      </Router>
    );
  }
}
