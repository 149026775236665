import React, { useState, useEffect } from "react";
import { isTablet, isDesktop, isMobile } from "react-device-detect";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import {
  ReactiveBase,
  ResultCard,
  ReactiveList,
} from "@appbaseio/reactivesearch";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Responsive from "react-responsive-decorator";
import { withRouter } from "react-router-dom";
import Header from "./layout/Header";
import BanerCarousel from "./layout/Carousel";
import Footer from "./layout/Footer";
import TrendingDeals from "./trending/TrendingDeals";
import CashBackStore from "./cashback/CashBackStore";
import BestProduct from "./best-product/BestProduct";
import CategoryDeals from "./category-deals/CategoryDeals";
import ApiService from "./services/ElasticsearchApi";

import { elasticSearchUrl } from "./common/util";
import ScrollButton from "./ScrollButton";

export const rating = (value) =>
  new Intl.NumberFormat("en-IN", {}).format(value);

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const App = () => {
  const [searchProduct, setSearchProduct] = useState(true);
  const [bestProducts, setBestProducts] = useState(0);
  const [couponKey, setCouponKey] = useState("");
  const [prevProductsStack, setPrevProductsStack] = useState([]);
  const [couponKeyStack, setCouponKeyStack] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState(0);
  const [categoryCouponKey,setCategoryCouponKey ] = useState("");
  const [prevCategoryProductsStack, setPrevCategoryProductsStack] = useState([]);
  const [categorycouponKeyStack, setCategoryCouponKeyStack] = useState([]);



  var bestProductsReqBody = {
    size: 0,
    query: {
      bool: {
        must: [
          { match: { IS_HOT: "Y" } },
          {
            bool: {
              should: [{ term: { STATUS: 1 } }, { term: { STATUS: 2 } }],
            },
          },
        ],
      },
    },
    aggs: {
      distinct_coupons: {
        composite: {
          size: isDesktop ? 5 : isTablet ? 3 : 1, // Number of unique coupons to return per page
          after: { coupon: couponKey },
          sources: [{ coupon: { terms: { field: "COUPON.keyword" } } }],
        },
        aggs: {
          top_dates: {
            top_hits: {
              size: 1,
              sort: [{ "DATE.keyword": { order: "asc" } }],
            },
          },
        },
      },
    },
  };
  function fetchProducts(bestProductsReqBody) {
    ApiService.getBestProducts(bestProductsReqBody)
      .then((data) => {
        console.log("response:",data);
        const products = [];
        const buckets = data.aggregations.distinct_coupons.buckets;
        const newCouponKey =
          data.aggregations.distinct_coupons.after_key.coupon;

        setCouponKeyStack((prevKeyStack) => [...prevKeyStack, couponKey]);

        buckets.map((bucket) => {
          const hits = bucket.top_dates.hits.hits;
          const _doc_count = bucket.doc_count;
          let source = {};
          hits.map((hit) => {
            source = hit._source;
            source._id = hit._id;
            source._doc_count = _doc_count;
            products.push(source);
          });
        });
        setBestProducts(products);
        setCouponKey(newCouponKey);
        setPrevProductsStack((prevStack) => [...prevStack, bestProducts]);
      })
      .catch((error) => {
        console.error("Error in Best product fetch:", error);
      });
  }

  function handlePageChange() {
    fetchProducts(bestProductsReqBody);
  }
  function handlePrevPage() {
    const prevProducts = [...prevProductsStack];
    const prevCouponKeys = [...couponKeyStack];

    const lastProducts = prevProducts.pop();
    const lastCouponKey = prevCouponKeys.pop();

    setBestProducts(lastProducts);
    setCouponKey(lastCouponKey);
    setPrevProductsStack(prevProducts);
    setCouponKeyStack(prevCouponKeys);
  }
  // Category Deals fetch recoreds

  var CategoryDealsReqData = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            bool: {
              should: [{ term: { STATUS: 1 } }, { term: { STATUS: 2 } }],
            },
          },
        ],
      },
    },
    aggs: {
      distinct_coupons: {
        composite: {
          size: isDesktop ? 5 : isTablet ? 3 : 1,
          after: { coupon: categoryCouponKey },
          sources: [{ coupon: { terms: { field: "CATEGORY.keyword" } } }],
        },
        aggs: {
          top_dates: {
            top_hits: {
              size: 1,
              sort: [{ "CATEGORY.keyword": { order: "desc" } }],
            },
          },
        },
      },
    },
  };

  function fetchCategoryProducts(CategoryDealsReqData) {
    ApiService.getCategoryProducts(CategoryDealsReqData)
      .then((data) => {
        const categoryDealsProducts = [];
        const buckets = data.aggregations.distinct_coupons.buckets;
        const newCategoryCouponKey =
          data.aggregations.distinct_coupons.after_key.coupon;

          setCategoryCouponKeyStack((prevKeyStack) => [...prevKeyStack, categoryCouponKey]);

        buckets.map((bucket) => {
          const hits = bucket.top_dates.hits.hits;
          const _doc_count = bucket.doc_count;
          let source = {};
          hits.map((hit) => {
            source = hit._source;
            source._id = hit._id;
            source._doc_count = _doc_count;
            categoryDealsProducts.push(source);
          });
        });
        setCategoryProducts(categoryDealsProducts);
        setCategoryCouponKey(newCategoryCouponKey);
        setPrevCategoryProductsStack((prevStack) => [...prevStack, categoryProducts]);
      })
      .catch((error) => {
        console.error("Error in Category Products fetch:", error);
      });
  }

  function handleCategoryPage() {
    fetchCategoryProducts(CategoryDealsReqData);
  }
  function handlePrevCategoryPage() {
    const prevCategoryProducts = [...prevCategoryProductsStack];
    const prevCategoryCouponKeys = [...categorycouponKeyStack];

    const lastCategoryProducts = prevCategoryProducts.pop();
    const lastCategoryCouponKey = prevCategoryCouponKeys.pop();

    setCategoryProducts(lastCategoryProducts);
    setCategoryCouponKey(lastCategoryCouponKey);
    setPrevCategoryProductsStack(prevCategoryProducts);
    setCategoryCouponKeyStack(prevCategoryCouponKeys);
  }

  useEffect(() => {
    fetchProducts(bestProductsReqBody);
    fetchCategoryProducts(CategoryDealsReqData);
  }, []);

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <ReactiveBase
        app="offer"
        url={elasticSearchUrl}
        type="_doc"
        reactivesearchAPIConfig={{
          recordAnalytics: false,
        }}
      >
        <div className="container-fluid top-header" style={{ padding: 0 }}>
          <Header setSearchProduct={setSearchProduct} />
          {/* <BanerCarousel /> */}
          <ScrollButton />
          <ReactiveList
            componentId="Baner"
            dataField="DATE.keyword"
            defaultQuery={() => {
              return {
                query: {
                  bool: {
                    must: [
                      { match: { IS_BANNER: "Y" } },
                      {
                        bool: {
                          should: [
                            { match: { STATUS: 1 } },
                            { match: { STATUS: 2 } },
                          ],
                        },
                      },
                    ],
                  },
                },
              };
            }}
            sortBy="desc"
            render={({ data }) => {
              return <BanerCarousel data={data} />;
            }}
            size={20}
          />
        </div>
        <div className="row pop-box">
          <div className="col-md-12  col-sm-12  marginBottom">
            <div>
              <div>
                <ReactiveList
                  componentId="TrendingDeals"
                  dataField="DATE.keyword"
                  distinctField="COUPON.keyword"
                  defaultQuery={() => {
                    return {
                      query: {
                        bool: {
                          must: [
                            { match: { IS_TRENDING: "Y" } },
                            {
                              bool: {
                                should: [
                                  { match: { STATUS: 1 } },
                                  { match: { STATUS: 2 } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    };
                  }}
                  size={isDesktop ? 5 : isTablet ? 3 : 1}
                  sortBy="desc"
                  pagination={true}
                  scrollOnChange={false}
                  // sortOptions={[{
                  //   label:"SELLER_NAME",
                  //   dataField:"SELLER_NAME",
                  //   sortBy:"asc"
                  // }]}
                  react={{
                    and: ["AggregationList2"],
                  }}
                  render={({ data }) => {
                    return (
                      <ReactiveList.ResultCardsWrapper>
                        <TrendingDeals data={data} />
                      </ReactiveList.ResultCardsWrapper>
                    );
                  }}
                  renderPagination={({
                    pages,
                    totalPages,
                    currentPage,
                    setPage,
                    setSize,
                  }) => {
                    const selectPage = Number.isFinite(totalPages) && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-warning  prev"
                          onClick={() => setPage(parseInt(currentPage - 1))}
                          disabled={currentPage === 0}
                        >
                          <MdOutlineArrowBackIos />
                        </button>

                        <button
                          type="button"
                          className="mb-2 btn btn-warning next"
                          onClick={() => setPage(parseInt(currentPage + 1))}
                          disabled={
                            isMobile
                              ? currentPage === totalPages - 2
                              : currentPage === totalPages - 1
                          }
                        >
                          <MdOutlineArrowForwardIos />
                        </button>
                      </div>
                    );
                    return selectPage;
                  }}
                />
              </div>
              
              <div>
                <BestProduct
                  data={
                    bestProducts && bestProducts.length > 0 ? bestProducts : []
                  }
                />
                <div>
                  <button
                    type="button"
                    className="btn btn-warning prev1"
                    onClick={handlePrevPage}
                    disabled={prevProductsStack.length === 1}
                  >
                    <MdOutlineArrowBackIos />
                  </button>

                  <button
                    type="button"
                    className="mb-2 btn btn-warning next1"
                    onClick={() => handlePageChange()}
                  >
                    <MdOutlineArrowForwardIos />
                  </button>
                </div>
              </div>
              <div>
                 <CategoryDeals
                  data={
                    categoryProducts && categoryProducts.length > 0 ? categoryProducts : []
                  }
                />
                <div>
                <button
                  type="button"
                  className="btn btn-warning  prev5"
                  onClick={handlePrevCategoryPage}
                  disabled={prevCategoryProductsStack.length === 1}
                >
                  <MdOutlineArrowBackIos />
                </button>

                <button
                  type="button"
                  className="mb-2 btn btn-warning next5"
                  onClick={handleCategoryPage}
                >
                  <MdOutlineArrowForwardIos />
                </button>
              </div>
                
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <br></br>
      </ReactiveBase>
    </div>
  );
};

export default Responsive(withRouter(App));
