import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
const Modal = (props) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    const urlParam = window.location.search;
    const redirectTimer = setTimeout(() => {
      window.location.href = "/out" + urlParam;
    }, 2000);
    return () => clearTimeout(redirectTimer);
  }, []);
  return (
    <div className="container d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
  <div className="col-md-6 col-sm-12  border">
    {/* <div className="rupeeIcon">Rupee icon</div> */}
    <div className="middleInfo">
      <h3>Please Wait</h3>
    </div>
    <div className="centerIcons">
      <div className="imgTitle">
        <div className="title">
          <div className="transfIcon"></div>
          <h5>PlanetOnSale</h5>
        </div>
        <div className="imagett">
          <Loader
            type="ThreeDots"
            color="orange"
            height={80}
            width={80} //3 secs
          />
        </div>
      </div>
    </div>
    {/* <div className="buttomBoder"></div> */}
    <div className="redirectInfo">
      <p>
        if you are not automatically re-directed, please{" "}
        <a href={url}>Click Here</a>
      </p>
    </div>
  </div>
</div>

  );
};
export default Modal;